.gm-style-iw {
  padding: 0px !important;
  background-color: white !important;
}

.gm-style-iw-d {
  overflow: hidden !important;
  border-radius: 5px !important;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.75) !important;
}

.gm-style-iw-chr {
  margin-bottom: -44px !important;
}

.gm-ui-hover-effect {
  background-image: url(/public/assets/images/traceIcon/close.svg) !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 72px !important;
  height: 42px !important;
  margin-right: -10px !important;
  z-index: 9999 important;
}

.gm-ui-hover-effect span {
  all: unset !important;
}

.InfoHeaderTruck {
  color: white;
  background-color: #e3537e;
  font-size: 13px;
  font-weight: 700;
  padding: 4.6px;
  width: 100%;
}

.InfoHeaderChassis {
  color: white;
  background-color: #4462ff;
  font-size: 13px;
  font-weight: 700;
  padding: 4.6px;
  width: 100%;
}

.InfoHeaderTrailer {
  color: white;
  background-color: #ff9d2a;
  font-size: 13px;
  font-weight: 700;
  padding: 4.6px;
  width: 100%;
}

.InfoHeaderText {
  margin-left: 19px;
  margin-top: 9px;
  margin-bottom: 9px;
}

.InfoBody {
  padding: 21px 21px 10px 21px !important;
  display: flex;
}

.InfoBodyTitle {
  margin-right: 21px;
}

.InfoBodyTitle p {
  margin-bottom: 11px;
  color: #475348;
  font-size: 11px;
  font-weight: 600;
}
.InfoBodyContent p {
  margin-bottom: 11px !important;
  color: #475348;
  font-size: 11px;
  font-weight: 400;
}

.MakerLabelTruck {
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  background-color: #e3537e;
  margin-bottom: 130px !important;
  position: relative;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.MakerLabelTruck::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #e3537e transparent transparent transparent;
}

.MakerLabelChassis {
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  background-color: #4462ff;
  margin-bottom: 130px !important;
  position: relative;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.MakerLabelChassis::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #4462ff transparent transparent transparent;
}

.MakerLabelTrailer {
  color: #fff !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  background-color: #ff9d2a;
  margin-bottom: 130px !important;
  position: relative;
  display: inline-block;
  padding: 3px 10px 3px 10px;
  border-radius: 3px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
}

.MakerLabelTrailer::after {
  content: '';
  position: absolute;
  bottom: -15px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 10px;
  border-style: solid;
  border-color: #ff9d2a transparent transparent transparent;
}

.Label {
  color: white;
  background-color: #e3537e;
  padding: 4.6px;
  margin-top: -50px !important;
}
